export const gp= {
    home: 'Home Page',
    product: 'Product Introduction',
    company: 'Company',
    aboutMe: 'AboutMe',
    introduce: ' G-plus is an Internet-based company, adhering to the "technology to deliver a better life" concept.',
    mission:'Through Internet thinking to help enterprises transform, make enterprises Internet, improve the operation efficiency of enterprises.',
    team:'A group of young people come together with dreams and a belief that technology can change the world.',
    business:'Business Direction',
    direction:'It is committed to building the most professional local life service platform in Southeast Asia, improving the quality of life of local people, helping the digital upgrading of local catering, retail, service and other industries, and providing customers with the most professional and convenient services.',
    project:'Project Introduction',
    pIntroduce:'Establish the operation mode of domestic industry research center and overseas operation center, and provide more Internet marketing tools to solve the "pain point" of business operation through four service solutions of digital operation, professional production, diversified marketing and intelligent service.',
    overseas:'Overseas Cooperation',
    cooperation:'At present,platform has stable suppliers and high-quality service team. With big data analysis, AI and other technical support, it expands the scope of service, optimizes the business system, and introduces a more intelligent, localized and diversified e-commerce service platform to provide users with a more extreme and convenient use experience.',
    environment:'Employees can enjoy the best visual landscape and natural lighting, and jointly create an equal, harmonious and multicultural working environment',
    phone:'Telephone：020-22836025',
    mail:'E-mail：gplus@guangplus.com',
    address:'Address: Room 102-202, Building 1, Guangzhou Lianxi Wisdom Bay Science Park, No. 3, Shenyong South 2nd Road, Lianxi, Tianhe District, Guangzhou',
    copyright:'Copyright  ©2019-2021.',
    icp :'YueICP no.19071864',
    link:'Product links'

}